<template>
  <div>
    <banregio-credit-card />
  </div>
</template>

<script>
import BanregioCreditCard from '@/@core/components/BanregioCreditCard.vue'

export default {
  components: {
    BanregioCreditCard,
  },
}
</script>

<style lang="scss" scoped>

</style>
